import React from 'react';
import { Link, graphql } from 'gatsby';
import Container from '../../components/Container';

function Pray({ link, text }) {
    return (
        <Link
            to={link}
            className='prayer text-decoration-none text-body fs-5 color-black w-100 bg-white border-bottom py-2 px-3 block'
        >
            {text}
        </Link>
    );
}

export default function Prayers({ data }) {
    return (
        <Container>
            <h1>Prayers</h1>
            <div className='row'>
                <div className='col-md-3 '>
                    <div
                        className='overflow-hidden d-flex rounded shadow mb-5'
                        style={{ maxHeight: '25rem' }}
                    >
                        <img
                            className='w-100 align-self-start'
                            src='https://cc.medienschaffer.de/wp-content/uploads/2020/09/mutter-teresa-2a.jpg'
                            alt=''
                        />
                    </div>
                </div>
                <div className='col-md-9'>
                    <div className='bg-white w-100 rounded d-flex flex-column overflow-hidden mb-5 border shadow-sm '>
                        {data.allWpPrayer.nodes.map((pray) => {
                            if (pray.prayerstype.nodes.length === 0)
                                return (
                                    <Pray
                                        key={pray.id}
                                        text={pray.title}
                                        link={pray.uri}
                                    />
                                );
                            return null;
                        })}
                    </div>
                    {data.allWpPrayertype.nodes.map((node) => {
                        return (
                            <>
                                <h3 key={node.id} className='ms-1 mb-3'>
                                    {node.name}
                                </h3>
                                <div className='bg-white w-100 rounded d-flex flex-column overflow-hidden mb-5 border shadow-sm '>
                                    {node.prayers.nodes.map((pray) => {
                                        return (
                                            <Pray
                                                key={pray.id}
                                                text={pray.title}
                                                link={pray.uri}
                                            />
                                        );
                                    })}
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </Container>
    );
}

export const query = graphql`
    {
        allWpPrayertype(sort: { order: ASC, fields: [name] }) {
            nodes {
                prayers {
                    nodes {
                        title
                        id
                        uri
                    }
                }
                id
                name
            }
        }

        allWpPrayer {
            nodes {
                title
                id
                uri
                prayerstype {
                    nodes {
                        name
                    }
                }
            }
        }
    }
`;
